<template>
    <div>You are being log out! Please wait...</div>
</template>

<script>
import { useCookies } from "vue3-cookies";
export default {
        setup() {
            const { cookies } = useCookies();
            return { cookies };
        },
		data() { 
		},
        mounted(){
            var Vthis = this;
            setTimeout(function(){
                Vthis.logout();
            },2000)
        },
        methods:{
            logout(){ 
                var Vthis = this;
                Vthis.axios
                .post('auth/logout')
                .then(() => { 
                    localStorage.clear(); 
                    this.cookies.remove("token");
                    Vthis.$router.push('/login');
                })
                .catch(error => console.log(error))
                .finally(() => { 
                }) 
            }
        }
}
</script>